import { useState } from "react";
import { useEffect } from "react";
/**
 * FetcherComponent fetches data from the provided API URL and sets the data in the parent component's state.
 * 
 * @param {Object} props - The props object.
 * @param {string} props.myUrl - The URL to fetch data from.
 * @param {Function} props.setData - The function to set the fetched data in the parent component's state.
 */

export default function FetcherComponent({ myUrl, setData }) {

console.log("Useffect reunning");
  async function fetchData() {
    const url = myUrl;
    const options = {
      method: 'GET',
      headers: {
        'accept': 'application/json'
      }
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
  
      setData(result.response[0].league.standings[0]);
      console.log("Data from " + myUrl + " has been fetched");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  fetchData();



	return null;


}
