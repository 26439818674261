import React, { useState } from 'react'
import FetcherComponent from '../fetching/FetcherComponent'
import EliteserieTable from './EliteserieTable'
import MockFetcherComponent from '../fetching/MockFetcherComponent'

export default function RenderComponent({myUrl}) {
    const [data, setData] = useState(null)

    return (
        <>
            <FetcherComponent setData={setData} myUrl={myUrl} />
            {/* <MockFetcherComponent setData={setData} myUrl={myUrl} /> */}
            <EliteserieTable currentStandings={data} />
        </>
    )
}
