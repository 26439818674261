import React from 'react'
import Navbar from './Navbar'

export default function Layout({content}) {
  return (
    <>
      <Navbar />
      <div id="contentContainer">
        {content}
      </div>
    </>
  )
}
