import React from 'react'
import '../styling/navbar.css'

export default function Navbar() {
  return (
    <div className='navbar'>
        <h1 className='mainTitle'>FOOTBALL NORWAY</h1>
    </div>
  )
}
