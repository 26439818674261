import React, { useEffect, useState } from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const TwitterFeed = () => {
  const [tweetHtml, setTweetHtml] = useState('');

  useEffect(() => {
    // Dynamically add the Twitter script
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charset = "utf-8";
    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  /* useEffect(() => {
    // Fetch the Twitter embed HTML
    fetch("https://publish.twitter.com/oembed?url=https%3A%2F%2Ftwitter.com%2FNorwayFooty%2Fstatus%2F1843723567332278612&buttonType=HashtagButton&partner=&hide_thread=false", {
      headers: {
        "sec-ch-ua": "\"Google Chrome\";v=\"129\", \"Not=A?Brand\";v=\"8\", \"Chromium\";v=\"129\"",
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": "\"Windows\""
      },
      referrer: "https://publish.twitter.com/?buttonType=HashtagButton&query=https%3A%2F%2Ftwitter.com%2FNorwayFooty%2Fstatus%2F1843723567332278612&widget=Tweet",
      referrerPolicy: "strict-origin-when-cross-origin",
      method: "GET",
      mode: "cors",
      credentials: "omit"
    })
    .then(response => response.json())
    .then(data => {
      setTweetHtml(data.html);
      console.log(data.html);

    })
    .catch(error => console.error('Error fetching tweet:', error));
  }, []); */

  return (<>
  
    <a data-height="500" data-width="400" data-tweet-limit = "3" className="twitter-timeline" href="https://twitter.com/NorwayFooty">
        Tweets by NorwayFooty
      </a>
      </>
    /* <div>
      <div dangerouslySetInnerHTML={{ __html: tweetHtml }} />
    </div> */
  );
};

export default TwitterFeed;

{/* <TwitterTimelineEmbed
      
        sourceType="profile"
        screenName="NorwayFooty"
        
      
      /> */}