import React from 'react'
import EliteserieRad from "./EliteserieRad"
import "../../styling/eliteserieTable.css"

export default function EliteserieTable({ prevStandings, currentStandings }) {
    if (!Array.isArray(currentStandings)) {
        return <div></div>;
    } else {
        return (
            <div id='eliteserieTable' className='frontPageDiv'>
                <table className='footballTable'>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Team</th>
                            <th>Points</th>
                            <th>Played</th>
                            <th>Wins</th>
                            <th>Draws</th>
                            <th>Losses</th>
                            <th>Goals</th>
                            <th>Goal Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentStandings.map((standingRow, index) => {
                            let rank = standingRow.rank;
                            let teamName = standingRow.team.name;
                            let points = standingRow.points;
                            let amountOfMatchesPlayed = standingRow.all.played;
                            let amountOfVictories = standingRow.all.win;
                            let amountOfDraws = standingRow.all.draw;
                            let amountOfLosses = standingRow.all.lose;
                            let goalsScored = standingRow.all.goals.for;
                            let goalsScoredOn = standingRow.all.goals.on;
                            let goalDifferential = standingRow.goalsDiff;

                            return (
                                <tr key={index}>
                                    <td>{rank}</td>
                                    <td>{teamName}</td>
                                    <td>{points}</td>
                                    <td>{amountOfMatchesPlayed}</td>
                                    <td>{amountOfVictories}</td>
                                    <td>{amountOfDraws}</td>
                                    <td>{amountOfLosses}</td>
                                    <td>{goalsScored}</td>
                                    <td>{goalDifferential}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}