import React from 'react'
import RenderComponent from './components/table/RenderComponent'
import TwitterFeed from './components/TwitterFeed'

export default function FrontPageContent() {
  return (
    <>
    <div  className="frontPageDiv">
      <RenderComponent myUrl = "https://what-client-footballnorway.azurewebsites.net/api/v1/leagues"/>
    </div>

    <div  className="frontPageDiv">
      <TwitterFeed />
    </div>
      
    </>
  )
}
